import React from "react"
import { graphql } from "gatsby"

import { useLocalize, guessLanguage } from '../utils/i18n';

import Layout from "../components/layout"
import SEO from "../components/seo"


class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const language = guessLanguage()
    const localize = useLocalize(language)

    return (
      <Layout 
        location={this.props.location} 
        title={siteTitle}
        locale={language}
      >
        <SEO title={localize(`page.404.title`)} lang={language}/>
        <h1>{localize(`page.404.header`)}</h1>
        <p>{localize(`page.404.text`)}</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
